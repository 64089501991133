import gql from "graphql-tag";

export const CREATE_CONTRACT_QUESTION = gql`
  mutation addContractQuestion($data: ContractQuestionWhereInput) {
    addContractQuestion(data: $data)
  }
`;
export const UPDATE_CONTRACT_QUESTION = gql`
  mutation updateContractQuestion(
    $where: ContractQuestionWhereInput
    $data: ContractQuestionWhereInput
  ) {
    updateContractQuestion(where: $where, data: $data)
  }
`;
