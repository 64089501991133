import React from "react";

import { Radio } from "antd";
import RadioButton from "shared/components/Radio";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";

import Item from "./Item";

const Question = ({ data, order, setQuestion }) => {
  const { question, answer, subQuestions } = data;
  const { t } = useTranslation(NAME_SPACES.PERSONAL_SPACE);

  const setItem = (index, subQuestionAnswer) => {
    subQuestions[index] = subQuestionAnswer;
    setQuestion(order, { question, answer, subQuestions });
  };

  return (
    <div className={"question"}>
      <p className={"text"}>
        <span className={"index"}>{order + 1}</span> {question}
      </p>
      <Radio.Group
        defaultValue={answer || false}
        onChange={({ target: { value } }) => {
          setQuestion(order, { question, answer: value, subQuestions });
        }}
      >
        <div className={"drawer-radio"}>
          <RadioButton value={false} label={t("NO")} checked={!answer} />
          <RadioButton value={true} label={t("YES")} checked={answer} />
        </div>
      </Radio.Group>
      {answer &&
        subQuestions.map((element, index) => (
          <Item
            key={`${index}_subQuestions`}
            data={element}
            index={index}
            setItem={setItem}
          />
        ))}
    </div>
  );
};

Question.propTypes = {
  data: PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.bool,
    subQuestions: PropTypes.array,
  }),
  order: PropTypes.number,
  setQuestion: PropTypes.func,
};

export default Question;
