import { PATHS } from "../../utils/constants";
import MedicalQuestionnaire from "./MedicalQuestionnaire";
import Contract from "./Contract";
import Summary from "./Summary";

const routes = [
  {
    path: PATHS.GUEST.PERSONAL_SPACE.SUMMARY,
    exact: true,
    component: Summary,
  },
  {
    path: PATHS.GUEST.PERSONAL_SPACE.MEDICAL_QUESTIONNAIRE,
    exact: true,
    component: MedicalQuestionnaire,
  },
  {
    path: PATHS.GUEST.PERSONAL_SPACE.CONTRACT,
    exact: true,
    component: Contract,
  },
];

export default routes;
