import React, { useEffect, useMemo, useState } from "react";
import "./styles.scss";

const Progress = ({ progress, previousProgress, hideBorders }) => {
  const [width, setWidth] = useState(previousProgress);

  useEffect(() => {
    requestAnimationFrame(() => {
      // console.log(progress, "progresss");
      setWidth(progress);
    });
  }, [progress]);

  return (
    <div className={`progress-container ${hideBorders && "no-borders"}`}>
      <div className={"progress"} style={{ width: `${width}%` }} />
    </div>
  );
};

export default Progress;
