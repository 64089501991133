export const CITIES = [
  { label: "Paris", value: "Paris" },
  { label: "Marseille", value: "Marseille" },
  { label: "Lyon", value: "Lyon" },
  { label: "Toulouse", value: "Toulouse" },
  { label: "Nice", value: "Nice" },
  { label: "Nantes", value: "Nantes" },
  { label: "Montpellier", value: "Montpellier" },
  { label: "Strasbourg", value: "Strasbourg" },
  { label: "Bordeaux", value: "Bordeaux" },
  { label: "Lille", value: "Lille" },
  { label: "Rennes", value: "Rennes" },
  { label: "Reims", value: "Reims" },
  { label: "Saint-Étienne", value: "Saint-Étienne" },
  { label: "Toulouse", value: "Toulouse" },
  { label: "Le Havre", value: "Le Havre" },
  { label: "Grenoble", value: "Grenoble" },
  { label: "Dijon", value: "Dijon" },
  { label: "Angers", value: "Angers" },
  { label: "Nîmes", value: "Nîmes" },
  { label: "Saint-Denis", value: "Saint-Denis" },
  { label: "Villeurbanne", value: "Villeurbanne" },
  { label: "Clermont-Ferrand", value: "Clermont-Ferrand" },
  { label: "Le Mans", value: "Le Mans" },
  { label: "Aix-en-Provence", value: "Aix-en-Provence" },
  { label: "Brest", value: "Brest" },
  { label: "Tours", value: "Tours" },
  { label: "Amiens", value: "Amiens" },
  { label: "Limoges", value: "Limoges" },
  { label: "Annecy", value: "Annecy" },
  { label: "Perpignan", value: "Perpignan" },
  { label: "Boulogne-Billancourt", value: "Boulogne-Billancourt" },
  { label: "Orléans", value: "Orléans" },
  { label: "Metz", value: "Metz" },
  { label: "Besançon", value: "Besançon" },
  { label: "Saint-Denis", value: "Saint-Denis" },
  { label: "Argenteuil", value: "Argenteuil" },
  { label: "Rouen", value: "Rouen" },
  { label: "Montreuil", value: "Montreuil" },
  { label: "Mulhouse", value: "Mulhouse" },
  { label: "Caen", value: "Caen" },
  { label: "Saint-Paul", value: "Saint-Paul" },
  { label: "Nancy", value: "Nancy" },
  { label: "Tourcoing", value: "Tourcoing" },
  { label: "Roubaix", value: "Roubaix" },
  { label: "Nanterre", value: "Nanterre" },
  { label: "Vitry-sur-Seine", value: "Vitry-sur-Seine" },
  { label: "Avignon", value: "Avignon" },
  { label: "Créteil", value: "Créteil" },
  { label: "Poitiers", value: "Poitiers" },
  { label: "Dunkerque", value: "Dunkerque" },
  { label: "Aubervilliers", value: "Aubervilliers" },
  { label: "Versailles", value: "Versailles" },
  { label: "Aulnay-sous-Bois", value: "Aulnay-sous-Bois" },
  { label: "Asnières-sur-Seine", value: "Asnières-sur-Seine" },
  { label: "Colombes", value: "Colombes" },
  { label: "Saint-Pierre", value: "Saint-Pierre" },
  { label: "Courbevoie", value: "Courbevoie" },
  { label: "Fort-de-France", value: "Fort-de-France" },
  { label: "Cherbourg-en-Cotentin", value: "Cherbourg-en-Cotentin" },
  { label: "Le Tampon", value: "Le Tampon" },
  { label: "Rueil-Malmaison", value: "Rueil-Malmaison" },
  { label: "Champigny-sur-Marne", value: "Champigny-sur-Marne" },
  { label: "Béziers", value: "Béziers" },
  { label: "Pau", value: "Pau" },
  { label: "La Rochelle", value: "La Rochelle" },
  { label: "Saint-Maur-des-Fossés", value: "Saint-Maur-des-Fossés" },
  { label: "Calais", value: "Calais" },
  { label: "Cannes", value: "Cannes" },
  { label: "Antibes", value: "Antibes" },
  { label: "Drancy", value: "Drancy" },
  { label: "Ajaccio", value: "Ajaccio" },
  { label: "Mérignac", value: "Mérignac" },
  { label: "Saint-Nazaire", value: "Saint-Nazaire" },
  { label: "Colmar", value: "Colmar" },
  { label: "Issy-les-Moulineaux", value: "Issy-les-Moulineaux" },
];

export const AGE = new Array(63).fill().map((el, ind) => ({
  label: `${ind + 18}`,
  value: `${ind + 18}`,
}));

export const CHILDREN = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5+", value: "5+" },
];

export const SITUATION = [
  { label: "Marié", value: "married" },
  { label: "Célibataire", value: "single" },
];
