import React, { useState } from "react";

import PropTypes from "prop-types";
import { FiArrowDown, FiArrowUp, FiCheck } from "react-icons/fi";
import VerticalMetric from "shared/components/VerticalMetric";

import "./styles.scss";

const IconOffer = require("shared/assets/images/svg/offer--cherry.svg");

const Offers = ({ data, onClick }) => {
  const [check, setCheck] = useState(false);
  const [showBox, setShowBox] = useState(false);

  return (
    <div className={`offer--card`}>
      <div className="offer--card--visible">
        <div className="offer--card--visible--name ">
          <img src={IconOffer} alt="" />
          <h4>{data.title}</h4>
          <p>
            Une assurance santé qui vous offre une couverture complète à un prix
            exceptionnel.
          </p>
        </div>
        <div className="offer--card--visible--chart ">
          <VerticalMetric items={data.metrics} />
        </div>
        <div className="offer--card--visible--price ">
          <span>
            30,75 <sup>€</sup> <sub>/mois</sub>
          </span>
        </div>
        <div className="offer--card--visible--action item">
          <div class="button button-1" onClick={onClick}>
            <div id="circle"></div>
            <a href="javascript:void(0)">Se faire rappeler</a>
          </div>
        </div>
      </div>

      <span className="show--more" onClick={() => setShowBox(!showBox)}>
        Plus d’info {showBox ? <FiArrowUp /> : <FiArrowDown />}
      </span>
      {showBox && (
        <div className="offer--card--hidden">
          <div className="offer--card--hidden--details">
            {data.details.map(({key, value}) => {
              return (
                <div className="offer--card--hidden--details--item">
                  <span>{key}</span>
                  <span>{value}</span>
                </div>
              );
            })}
          </div>
          <div className="offer--card--hidden--price ">
            <p>Tarif annuel</p>
            <span>
              357,00 <sup>€</sup> <sub>/an</sub>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

Offers.propTypes = {
  data: PropTypes.arrayOf,
  onClick: PropTypes.func,
};

Offers.defaultProps = {
  data: [
    {
      image:
        "https://images.samsung.com/is/image/samsung/p6pim/ca/un65au8000fxzc/gallery/ca-crystal-uhd-au8000-un65au8000fxzc-426400554?$PD_SHOP_MAIN$",
      ref: "0001665",
      name: "Whirlpool Lave vaisselle - LV AKR2469N",
      serial: "02653GEK7T9A002",
      nFacture: "05102021",
      date: "05.10.2020",
    },
  ],
  onClick: () => {
    console.log("clicked");
  },
};

export default Offers;
