
import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyC6RSPlprEZI7n__VWj51FawU2zJ6qDx5c",
    authDomain: "simulator-8a86a.firebaseapp.com",
    projectId: "simulator-8a86a",
    storageBucket: "simulator-8a86a.appspot.com",
    messagingSenderId: "603911488964",
    appId: "1:603911488964:web:ee587a886c7e377270d091",
    measurementId: "G-LXMTDCZG3X"
  };
    
firebase.initializeApp(firebaseConfig);
var database = firebase.database();
  
export default database;