import React from "react";

import PropTypes from "prop-types";
import Input from "@taillislabs/input";
import { Row, Col } from "antd";

const Item = ({ data, index, setItem }) => {
  const { question, answer } = data;
  return (
    <div className={"input-question"}>
      <Row>
        <Col xl={12} md={12} xs={24}>
          <Input
            placeholder={question}
            defaultValue={answer || ""}
            onChange={({ target: { value } }) =>
              setItem(index, { question, answer: value })
            }
          />
        </Col>
      </Row>
    </div>
  );
};

Item.propTypes = {
  data: PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.bool,
  }),
  index: PropTypes.number,
  setItem: PropTypes.func,
};

export default Item;
