import React from "react";
import "../styles.scss";
import propTypes from "prop-types";
import { Popover, Button } from "antd";

const HowChoose = ({ title }) => {
  return (
    <div className="product--item--head--howChoose">
      <h4>{title}</h4>
      <div className="product--item--head--howChoose--item">
        <span>Moyen</span>
        <span><b>150 €</b></span>
      </div>
      <div className="product--item--head--howChoose--item">
        <span>Fort</span>
        <span><b>250 €</b></span>
      </div>
      <div className="product--item--head--howChoose--item">
        <span>Max.</span>
        <span><b>+250 €</b></span>
      </div>
    </div>
  );
};

HowChoose.prototype = {
  // howChoose: propTypes.node,
};

export default HowChoose;
