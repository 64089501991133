import React from "react";
import propTypes from "prop-types";

const Container = ({ children }) => (
  <div className={"container"}>{children}</div>
);

Container.defaultProps = {};

Container.prototype = {};

export default Container;
