import React from "react";
import "./styles.scss";
import Head from "./Head";
import HowChoose from "./HowChoose";
import { Radio } from 'antd';
import propTypes from "prop-types";
const optique = require("shared/assets/images/products/product--icon--optique.svg");


const Product = ({ icon, title ,description, onPress }) => (
  <div className={"product--item"}>
    <Head title={title} icon={icon} howChoose={<HowChoose title={description} />} />
    <Radio.Group  size="large" onChange={(e)=> onPress(e.target.value)}>
      <Radio.Button value="min" defaultChecked>Min.</Radio.Button>
      <Radio.Button value="moyen">Moyen</Radio.Button>
      <Radio.Button value="fort">Fort</Radio.Button>
      <Radio.Button value="max">Max.</Radio.Button>
    </Radio.Group>
   
  </div>
);

Product.defaultProps = {
  icon: optique,
  title: "Optique",
  description: "text here"
};

Product.prototype = {
  icon: propTypes.string,
  title: propTypes.string,
  description: propTypes.string,
  onPress: propTypes.func,
};


export default Product;
