import React, { useContext, useEffect, useState } from "react";
import View from "./View";
import { useMutation } from "react-apollo";
import { CREATE_CONTRACT_QUESTION } from "shared/graphql/contractQuestion/mutation";
import validation from "./validation";
import { NAME_SPACES } from "shared/locales/constants";
import { useTranslation } from "react-i18next";
import cuid from "cuid";
import { StoreContext } from "shared/store";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { get } from "lodash";
import { INITIAL_DATA } from "./data";
import { UPDATE_CONTRACT_QUESTION } from "shared/graphql/contractQuestion/mutation";

const Component = ({}) => {
  const history = useHistory();
  const [addContractQuestion] = useMutation(CREATE_CONTRACT_QUESTION, {
    onCompleted: () => history.goBack(),
  });
  const [updateContractQuestion] = useMutation(UPDATE_CONTRACT_QUESTION, {
    onCompleted: () => history.goBack(),
  });
  const { t } = useTranslation(NAME_SPACES.CONTRACT);
  const { user } = useContext(StoreContext);
  const location = useLocation();
  const {
    member: { id },
  } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { responses } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    depth: 10,
  });
  const [answers, setAnswers] = useState(
    get(responses, "[0].answers[0]", null) || INITIAL_DATA
  );
  return (
    <View
      initialValues={answers}
      validation={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
      onSubmit={(data) => {
        const responseId = get(responses, "[0].id", null);
        const payload = {
          data: {
            id: responseId || cuid(),
            user: { id: user.id },
            answers: data,
            relatedPerson: { id: user.id === id ? null : id },
          },
        };
        responseId
          ? updateContractQuestion({
              variables: { where: { id: responseId }, ...payload },
            })
          : addContractQuestion({
              variables: { ...payload },
            });
      }}
    />
  );
};

Component.propTypes = {};

Component.defaultProps = {};
export default Component;
