import React from "react";
import { Radio as RadioButton } from "antd";
import "./style.scss";

const Radio = ({ label, value, checked, icon, variant }) => {
  return (
    <div className={`radio-button ${checked && "selected"} radio-${variant}`}>
      <RadioButton value={value}>
        {icon && (
          <div className={"radio-icon"}>
            <img className={"radio-img"} src={icon} />
          </div>
        )}
        <p className={`drawer-radio-item ${checked && "selected-text"}`}>
          {label}
        </p>
      </RadioButton>
    </div>
  );
};

Radio.propTypes = {};

Radio.defaultProps = {};
export default Radio;
