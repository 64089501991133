import React from "react";
import "../style.scss";
import { Col, Row } from "antd";
import { fullWidth } from "../../Quotes/style";

const Component = ({
  name,
  children,
  plan,
  icon,
  spouse,
  username,
  passcode,
}) => {
  const messages = {
    true: `Vous êtes marié et vous avez ${children} enfants,`,
    false: `Vous êtes célibataire,`,
  };
  return (
    <div className={"header"}>
      <Row style={fullWidth}>
        <Col xl={12} sm={24}>
          <p className={"name"}>Bounjour {name} 👋,</p>
          <p className={"description"}>{messages[spouse > 0]}</p>
          <p className={"description"}>
            Merci de remplir questionnaire medical et votre contrat
          </p>
        </Col>
        <Col xl={12} xs={24} style={fullWidth}>
          <Row style={fullWidth} className={"mt-s-24"}>
            <Col xl={24} xs={12} lg={12}>
              <p className={"right md-left plan-title"}>
                <img src={icon} alt="" width={24} />
                {plan}
              </p>
            </Col>
            <Col xl={24} xs={12} lg={12}>
              <p className={"right description"}>
                username: <span>{username}</span>
              </p>
              <p className={"right description"} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

Component.propTypes = {};

Component.defaultProps = {};
export default Component;
