import React from "react";
import "./styles.scss";
import avatar from "shared/assets/images/family-invitation.svg";
import { CopyOutlined } from "@ant-design/icons";

const Component = ({ link, onCloseClick }) => (
  <div className={"invitation-modal"}>
    <div className={"content"}>
      <img src={avatar} alt="" />
      <div className={"right-section"}>
        <p className={"right-title"}>Ci-dessous, vos accès temporaires :</p>
        <div className={"link"}>
          <p>{link}</p>
          <CopyOutlined
            onClick={() => {
              navigator.clipboard.writeText(link);
            }}
            style={{ color: "#5563A5", fontSize: 18, cursor: "pointer" }}
          />
        </div>
        <div
          className={"finalize-button finalize-button-large"}
          onClick={onCloseClick}
        >
          <p className={"centered"}>ok</p>
        </div>
      </div>
    </div>
  </div>
);

export default Component;
