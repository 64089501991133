import React from "react";
import { Col, Row } from "antd";
import PegaHeader from "shared/components/Layout/Public/Header";
import IconMan from "shared/assets/images/svg/pega--man.svg";
import "../styles.scss";
import { Formik } from "formik";
import validation from "./validation";
import { NAME_SPACES } from "shared/locales/constants";
import Input from "@taillislabs/input";
import { bindInputProps } from "utils/helpers/input";
import { useTranslation } from "react-i18next";
import { fullWidth } from "../style";
import { STORAGE_KEYS } from "utils/constants";
import { FINAL_STEP, FINALIZE, PRODUCTS, OFFERS } from "../constants";
import { useMutation } from "react-apollo";
import { CREATE_USER, SIGN_IN, SIGN_UP } from "shared/graphql/user/mutations";
import { StepStore } from "shared/components/StepScreens";
import Footer from "../../../shared/components/Footer";
import HeadingBack from "shared/components/HeadingBack";
import Container from "shared/components/Container";
import { FiUmbrella } from "react-icons/fi";

const Finalize = ({ step: { changeTab }, user, setUser, product }) => {
  const { t } = useTranslation(NAME_SPACES.ON_BOARDING);
  const FORM = t("FORM", {
    returnObjects: true,
  });

  const onSubmit = async ({ email, phone }) => {
    setUser((user) => ({
      ...user,
      email,
      phone,
    }));
    changeTab(OFFERS);
  };
  return (
    <>
      <PegaHeader />
      <div className={"simulator--wrapper bg--linear-linear"}>
        <Container>
          <Row style={fullWidth} justify="center" align="center">
            <Col xl={16} md={16} sm={24} xs={24}>
              <div className="heading--summary p-top-40">
                <img src={IconMan} alt="" />
                <p>
                  À quoi servent ces informations ?<br />
                  Vous recevrez le récapitulatif de votre comparatif à votre
                  adresse email.
                </p>
              </div>
              <div className="simulator--wrapper--head">
                <HeadingBack
                  onClick={() => changeTab(PRODUCTS)}
                  title={"Votre nom et prénom"}
                />
              </div>

              <Formik
                initialValues={{
                  email: "",
                  phone: "",
                }}
                onSubmit={onSubmit}
                validationSchema={validation(
                  t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
                    returnObjects: true,
                  })
                )}
              >
                {({ handleSubmit, ...formProps }) => (
                  <>
                    <div className="simulator--wrapper--form">
                      <Input
                        placeholder={FORM.EMAIL}
                        hidePlaceholder={true}
                        {...bindInputProps({ name: "email", ...formProps })}
                      />
                      <Input
                        hidePlaceholder={true}
                        placeholder={FORM.PHONE}
                        {...bindInputProps({ name: "phone", ...formProps })}
                      />
                    </div>

                    <div className="simulator--wrapper--action">
                      <div class="button button-xlarge" onClick={handleSubmit}>
                        <div id="circle"></div>
                        <a href="javascript:void(0)">{"Accéder à vos devis"}</a>
                      </div>
                    </div>
                  </>
                )}
              </Formik>

              <div className="simulator--wrapper--cgu">
                <p>
                  En cliquant sur le bouton « Accéder à vos devis », vous
                  acceptez les conditions générales d'utilisation. La société
                  RELAIS FINANCE, située au 27, avenue du Grand Tétras, 31860
                  Labarthe-sur-Lèze et immatriculée au registre du commerce de
                  Toulouse sous le numéro 892 228 334, est le responsable de
                  traitement des données à caractère personnel collectées. Leur
                  traitement permet notamment : la comparaison d'offres
                  personnalisées d'assurances, la facilitation du processus de
                  souscription aux offres choisies, la création d'un espace
                  personnel, l'analyse et reporting de statistiques anonymes,
                  l'envoi de contenus personnalisés, la mise en relation pour
                  les offres choisies, les actions de prospection commerciale et
                  de gestion de la relation client par RELAIS FINANCE, les
                  actions de prospections commerciales par nos partenaires si
                  vous avez souhaitez leur transmettre votre profil. L'ensemble
                  des données collectées sur le formulaire est obligatoire. En
                  leur absence, la comparaison d'offres ne pourra être effectuée
                  et l'espace personnel ne pourra être créé. Les données à
                  caractère personnel collectées sont destinées aux : services
                  habilités de RELAIS FINANCE & RELAIS ASSURANCE et ses
                  éventuels sous-traitants, assureurs pour lesquels vous avez
                  souhaitez obtenir une mise en relation et leurs éventuels
                  sous-traitants, assureurs auprès desquels vous avez souhaitez
                  souscrire à une offre via le site de RELAIS FINANCE & RELAIS
                  ASSURANCE et leurs éventuels sous-traitants ; ainsi que le cas
                  échéant aux : sociétés d'assurance, intermédiaires
                  d'assurance, plateformes d'affiliation et de prospection.
                  RELAIS FINANCE conserve vos données uniquement le temps
                  nécessaire pour les finalités poursuivies, conformément aux
                  prescriptions légales. Elles sont conservées sur des serveurs
                  situés en France et sont traitées par nos équipes techniques
                  qui sont soumises à des règles strictes aux fins de garantir
                  la sécurité et la confidentialité de vos données. Vous
                  disposez d'un droit d'accès, de modification, de suppression
                  et de rectification des données vous concernant, ainsi que
                  d'autres droits conformément au Règlement Général sur la
                  Protection des Données. Vous pouvez exercer ces droits en
                  adressant votre demande au délégué à la protection des données
                  de RELAIS FINANCE par courriel à l'adresse suivante :{" "}
                  <a href="mailto:contact@relaisfinance.com">
                    contact@relaisfinance.com
                  </a>{" "}
                  , ou par voie postale. En cas de réclamation sur la gestion de
                  vos données personnelles, vous pouvez contacter la CNIL. Pour
                  en savoir plus au sujet des traitements effectués sur vos
                  données à caractère personnel et sur vos droits, reportez-vous
                  à notre politique de confidentialité et à celles de nos
                  partenaires en cliquant ici. Conformément à la loi 2014-344 du
                  17 mars 2014 relative à la consommation, vous disposez d'un
                  droit d'opposition au démarchage téléphonique en vous
                  inscrivant gratuitement sur la liste d'opposition Bloctel.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default StepStore(Finalize);
