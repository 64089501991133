export const PATHS = {
  PUBLIC: {
    QUOTES: "/",
  },
  GUEST: {
    PERSONAL_SPACE: {
      SUMMARY: "/personal-space/summary",
      MEDICAL_QUESTIONNAIRE: "/personal-space/medical-questionnaire",
      CONTRACT: "/personal-space/contract",
    },
  },
};

export const CAPABILITY_CONFIG = {
  MAX_LINK_AGE: 10,
  MAX_LINK_AGE_UNIT: "Jours",
};

export const TIME_UNITS = {
  YEARS: "years",
  MOUNTHS: "MOUNTHS",
  DAYS: "days",
  HOURS: "hours",
  MINUTS: "minuts",
};

export const GENDERS = {
  MALE: "M",
  FEMALE: "F",
};

export const PLAN_COST_TYPES = {
  YEARLY: "YEARLY",
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
};

export const SIGN_UP_URL = "https://pegahealth.com/contactez-nous";

export const PHONE_PREFIX = "+212";

export const RELATIONSHIP = {
  CHILD: "CHILD",
  WIFE: "WIFE",
  HUSBAND: "HUSBAND",
};

export const PERMISSIONS = {
  READ: "read",
  WRITE: "write",
};

export const META_TYPE = {
  PROSPECT: "prospect",
};

export const CAPABILITY_REASONS = {
  SIMULATION: "simulation",
};

export const STORAGE_KEYS = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  STATE: "state",
  USER: "user",
  PLAN: "plan",
  INVITATION: "invitation",
};

export const ORGANIZATION_ID = "1";

export const MOBILE_MAX_WIDTH = 767;

export const PARENTAL_LINK = ["ME", "SPOUSE", "DAD", "MOM"];

export const CITIES = ["Casablanca", "Meknes", "Rabat"];

export const STATE = ["Etudiant", "Salarié"];

export const HELP_URL = "";
