export const INITIAL_DATA = {
  birthdate: "",
  gender: "",
  name: "",
  state: "",
  parental: "",
  address: "",
  city: "",
  email: "",
  phone: "+212 6",
  weight: "",
  height: "",
};
