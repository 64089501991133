/* eslint-disable consistent-return */
import { Select, Spin } from "antd";
import React from "react";
import "./styles.scss";
import propTypes from "prop-types";
import { style } from "./style";

const { Option } = Select;

const Component = ({
  placeholder,
  items,
  onSearch,
  loading,
  onChange,
  hidePlaceholder,
  value,
}) => (
  <div>
    {!hidePlaceholder && <p className={"select-placeholder"}>{placeholder}</p>}
    <Select
      notFoundContent={loading ? <Spin size="small" /> : null}
      showSearch
      placeholder={placeholder}
      className="select__custom"
      size="large"
      style={style}
      value={value}
      onSearch={onSearch}
      onSelect={onChange}
    >
      {items.map((item) => (
        <Option value={item.value} key={`option-${item.value}`}>
          {item.label}
        </Option>
      ))}
    </Select>
  </div>
);

Component.defaultProps = {
  placeholder: "",
  hidePlaceholder: false,
  onChange: () => {},
};

Component.propTypes = {
  placeholder: propTypes.string,
  items: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string,
      value: propTypes.string,
    })
  ),
  onSearch: propTypes.func,
  onChange: propTypes.func,
  loading: propTypes.bool,
  hidePlaceholder: propTypes.bool,
  value: propTypes.string,
};

export default Component;
