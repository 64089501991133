import React, { useContext, useState } from "react";

import { TabPanel, TabSwitcher } from "shared/components/StepScreens";

import {
  PRODUCTS,
  STEPS_LIST,
  FINAL_STEP,
  SUMMARY,
  FINALIZE,
  GENDER,
  BIRTHDATE,
  PROFESSION,
  SOCIAL_REGIMEN,
  INSURANCE_STATUS,
  BINDING_DATE,
  OFFERS,
  SUCCESS
} from "./constants";
import FinalStep from "./FinalStep";
import Products from "./Products";
import Summary from "./Summary";
import Finalize from "./Finalize";
import Gender from "./Gender";
import Birthdate from "./Birthdate";
import Success from "./Success";
import Profession from "./Profession";
import SocialRegimen from "./SocialRegimen";
import BindingDate from "./BindingDate";
import Offers from "./Offers";
import InsuranceStatus from "./InsuranceStatus";
import { StoreContext } from "shared/store";

const Quote = (props) => {
  const [product, setProduct] = useState({});
  const { user, setUser } = useContext(StoreContext);
  console.log(user);
  return (
    <TabSwitcher steps={STEPS_LIST}>
      <TabPanel whenActive={SUMMARY}>
        <Summary setUser={setUser} {...props} />
      </TabPanel>
      <TabPanel whenActive={GENDER}>
        <Gender setUser={setUser} {...props} />
      </TabPanel>
      <TabPanel whenActive={BIRTHDATE}>
        <Birthdate setUser={setUser} {...props} />
      </TabPanel>
      <TabPanel whenActive={PROFESSION}>
        <Profession setUser={setUser} {...props} />
      </TabPanel>
      <TabPanel whenActive={SOCIAL_REGIMEN}>
        <SocialRegimen setUser={setUser} {...props} />
      </TabPanel>
      <TabPanel whenActive={INSURANCE_STATUS}>
        <InsuranceStatus setUser={setUser} {...props} />
      </TabPanel>
      <TabPanel whenActive={BINDING_DATE}>
        <BindingDate setUser={setUser} {...props} />
      </TabPanel>
      <TabPanel whenActive={PRODUCTS}>
        <Products
          user={user}
          setUser={setUser}
          product={product}
          {...props}
        />
      </TabPanel>
      <TabPanel whenActive={FINALIZE}>
        <Finalize user={user} setUser={setUser} {...props} />
      </TabPanel>
      <TabPanel whenActive={OFFERS}>
        <Offers user={user} setUser={setUser} {...props} />
      </TabPanel>
      <TabPanel whenActive={SUCCESS}>
        <Success user={user} setUser={setUser} {...props} />
      </TabPanel>
      {/* <TabPanel whenActive={FINAL_STEP}>
        <FinalStep user={user} setUser={setUser} {...props} />
      </TabPanel> */}
    </TabSwitcher>
  );
};

export default Quote;
