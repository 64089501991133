import React from "react";

import Item from "./Item";

const Component = ({ items }) => {
  return items.map((data, index) => {
    return <Item {...data} key={index} />;
  });
};

Component.propTypes = {};

Component.defaultProps = {};

export default Component;
