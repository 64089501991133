import * as yup from "yup";

export default (messages) => {
  return yup.object().shape({
    phone: yup.string().required(messages.PHONE),
    email: yup.string().email(messages.EMAIL).required(messages.REQUIRED),
    birthdate: yup.date().required(messages.REQUIRED),
    name: yup.string().required(messages.REQUIRED),
    state: yup.string().required(messages.REQUIRED),
    parental: yup.string().required(messages.REQUIRED),
    address: yup.string().required(messages.REQUIRED),
    city: yup.string().required(messages.REQUIRED),
    weight: yup
      .number(messages.NUMBER)
      .positive(messages.NUMBER)
      .required(messages.REQUIRED),
    height: yup
      .number(messages.NUMBER)
      .positive(messages.NUMBER)
      .required(messages.REQUIRED),
  });
};
