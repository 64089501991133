import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import Select from "./Select";
import Input from "./TextInput";

const MODES = {
  input: Input,
  select: Select,
};

const ParagraphInput = ({ mode, text, ...props }) => {
  const [value, setValue] = useState([]);
  const View = useMemo(() => MODES[mode], [mode]);
  return (
    <p className={"paragraph-input"}>
      {text.split(" ").map((str) => {
        if (str.startsWith("{{") && str.endsWith("}}")) {
          str = str.replace("{{", "").replace("}}", "");
          return (
            <div className={"input-spacer"}>
              <View str={str} value={value} setValue={setValue} {...props} />
            </div>
          );
        }
        return `${str} `;
      })}
    </p>
  );
};

ParagraphInput.propTypes = {
  text: PropTypes.bool,
  mode: PropTypes.string,
};

ParagraphInput.defaultProps = {
  text: "",
  mode: "input",
};

export default ParagraphInput;
