import { split } from "apollo-link";
import { getMainDefinition, hasDirectives } from "apollo-utilities";
import bffLink from "./links/bff.link";
import {
  authBffLink,
  AUTH_ANNOTATION,
  PRIVATE_AUTH_ANNOTATION
} from "./links/authBff.link";
import WSLink from "./links/ws.link";
import { authLink } from "./links/utils";

const rootLink = split(
  ({ query }) =>
    hasDirectives([AUTH_ANNOTATION], query) ||
    hasDirectives([PRIVATE_AUTH_ANNOTATION], query),
  authBffLink,
  bffLink
);

const link = split(
  ({ query }) => {
    const { operation } = getMainDefinition(query);
    return operation === "subscription";
  },
  WSLink,
  authLink.concat(rootLink)
);

export default link;
