import React, {useState} from "react";
import { Col, Row } from "antd";
import { Radio } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import RadioButton from "shared/components/Radio";
import HeadingBack from "shared/components/HeadingBack";
import { StepStore } from "shared/components/StepScreens";
import { NAME_SPACES } from "shared/locales/constants";
import { fullWidth } from "../style";
import PegaHeader from "shared/components/Layout/Public/Header";
import male from "shared/assets/images/avatars1.svg";
import female from "shared/assets/images/avatars2.svg";
import { GENDERS } from "utils/constants";
import { BIRTHDATE, SUMMARY } from "../constants";
import Container from "shared/components/Container";
import { CgBulb } from "react-icons/cg";

const Gender = ({ step: { changeTab }, setUser }) => {
  const { t } = useTranslation(NAME_SPACES.ON_BOARDING);
  const STRINGS = t("GENDER", {
    returnObjects: true,
  });

  return (
    <>
      <PegaHeader />
      <div className={"simulator--wrapper bg--linear-linear"}>
        <Container>
          <Row style={fullWidth} justify="center" align="center">
            <Col xl={16} md={16} sm={24} xs={24}>
              <div className="simulator--wrapper--head">
                <HeadingBack
                  onClick={() => changeTab(SUMMARY)}
                  title={STRINGS.GREETING}
                />
              </div>

              <div className={"gender--radio"}>
                <Radio.Group
                  onChange={({ target: { value }}) => {
                    setUser((user) => {
                      console.log(user,'Gdnr');
                      return { ...user, gender: value };
                    });
                    changeTab(BIRTHDATE);
                  }}
                >
                  <div className={"gender--radio--grid"}>
                    <RadioButton
                      value={GENDERS.MALE}
                      label={STRINGS.MALE}
                      variant={"large"}
                      checked={false}
                      icon={female}
                    />
                    <RadioButton
                      value={GENDERS.FEMALE}
                      label={STRINGS.FEMALE}
                      variant={"large"}
                      checked={false}
                      icon={male}
                    />
                  </div>
                </Radio.Group>
              </div>

              <div className="why--question">
                <h6>
                  <CgBulb size={24} /> Pourquoi cette question
                </h6>
                <p>
                  Les assureurs ont besoin de cette information pour établir
                  votre dossier.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Gender.propTypes = {
  step: PropTypes.shape({
    changeTab: PropTypes.func,
  }),
  setUser: PropTypes.func,
};

export default StepStore(Gender);
