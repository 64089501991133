/* eslint-disable no-param-reassign */
/* eslint-disable implicit-arrow-linebreak */
import { WebSocketLink } from "apollo-link-ws";
import { BFF_URL_SUBSCRIPTIONS as URL_SUBSCRIPTIONS } from "../../../../App/config";

const wsLink = new WebSocketLink({
  uri: URL_SUBSCRIPTIONS,
  options: {
    reconnect: true,
  },
});

const subscriptionMiddleware = {
  applyMiddleware: async (options, next) => {
    const token = localStorage.getItem("access_token");
    options.authorization = `Bearer ${token}`;
    next();
  },
};

export default wsLink.subscriptionClient.use([subscriptionMiddleware]);
