import React, { createContext, useState } from "react";
import Progress from "../Progress";
import { findIndex } from "lodash";

const context = createContext({});

const { Provider, Consumer } = context;

const TabPanel = ({ whenActive, children }) => (
  <Consumer>
    {({ activeTabId }) => (activeTabId === whenActive ? children : null)}
  </Consumer>
);

const TabSwitcher = ({ children, steps, progressBar = {} }) => {
  const [activeTabId, setActiveTabId] = useState(steps[0]);
  return (
    <Provider value={{ activeTabId, changeTab: setActiveTabId }}>
      <Progress
        progress={
          ((findIndex(steps, (e) => e === activeTabId) + 1) * 100) /
          steps.length
        }
        previousProgress={
          findIndex(steps, (e) => e === activeTabId) === 0
            ? 1
            : (findIndex(steps, (e) => e === activeTabId) * 100) / steps.length
        }
      />
      {children}
    </Provider>
  );
};

const StepStore = (WrappedComponent) => (props) =>
  (
    <Consumer>
      {(value) => <WrappedComponent {...props} step={value} />}
    </Consumer>
  );

export { TabPanel, TabSwitcher, StepStore };
