import React,{useEffect} from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import HeadingBack from "shared/components/HeadingBack";
import Container from "shared/components/Container";
import { StepStore } from "shared/components/StepScreens";
import { NAME_SPACES } from "shared/locales/constants";
import { fullWidth } from "../style";
import PegaHeader from "shared/components/Layout/Public/Header";
import { BINDING_DATE, FINALIZE, FINAL_STEP, OFFERS } from "../constants";
import { BsFillCircleFill } from "react-icons/bs";
import "./styles.scss";
import { CheckOutlined } from "@ant-design/icons";
import { FiCheck } from "react-icons/fi";
import successIcon from "shared/assets/images/svg/success.svg";
import database from "shared/firebase";

const Success = ({ step: { changeTab }, setUser, user }) => {
  const { t } = useTranslation(NAME_SPACES.ON_BOARDING);
  const STRINGS = t("PRODUCT", {
    returnObjects: true,
  });
  useEffect(() => {
    console.log(user,'data');
      const messagesRef = database.ref("simulation");
      const newSend = messagesRef.push();
      newSend.set(user);
  }, [])
  return (
    <>
      <PegaHeader />
      <div className={"simulator--wrapper bg--linear-linear flex-center"}>
        <Container>
          <Row style={fullWidth} justify="center" align="center">
            <Col xl={24} md={24} sm={24}>
              <div className="heading--summary">
                <img src={successIcon} alt="" className="image" />
                <h3>Votre dossier a été soumis pour validation avec succès.</h3>
                <p>
                Nous vous contacterons dans les plus brefs délais.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Success.propTypes = {
  step: PropTypes.shape({
    changeTab: PropTypes.func,
  }),
  setUser: PropTypes.func,
};

export default StepStore(Success);
