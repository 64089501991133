import PropTypes from "prop-types";
import React, { useMemo } from "react";
import simple from "./simple";
import widthCollapse from "./widthCollapse";
const MODES = {
  simple: simple,
  widthCollapse: widthCollapse,
};

const Documents = ({ mode, ...props }) => {
  const View = useMemo(() => MODES[mode], [mode]);
  return <View {...props} />;
};

Documents.propTypes = {
  mode: PropTypes.oneOf(Object.keys(MODES)),
};

Documents.defaultProps = {
  mode: "widthCollapse",
};

export default Documents;
