import React from "react";
import "./style.scss";
import propTypes from "prop-types";

const logo = require("shared/assets/images/relais-finance_logo.png");

const Logo = ({ logo, label }) => (
  <div className={"logo--area"}>
    <img src={logo} alt="logo" className={"pega-logo"} />
    <span>{label}</span>
  </div>
);

Logo.defaultProps = {
  logo: logo,
  label: "Company XYZ",
};

Logo.prototype = {
  logo: propTypes.string,
  label: propTypes.string,
};

export default Logo;
