import React from "react";
import AutosizeInput from "react-input-autosize";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { MOBILE_MAX_WIDTH } from "utils/constants";

const Component = ({ str, value, setValue, ...props }) => {
  const { width } = useWindowDimensions();
  return (
    <AutosizeInput
      name={str}
      value={value}
      onChange={(e) => {
        setValue(e);
      }}
      placeholder={str}
      inputStyle={{
        backgroundColor: "transparent",
        borderColor: "transparent",
        font: "normal normal medium 32px/38px Rubik",
        lineHeight: "32px",
        fontSize: width > MOBILE_MAX_WIDTH ? 28 : 18,
        fontWeight: 500,
        color: "#009640",
        borderBlockWidth: 0,
        minWidth: 100,
        textAlign: "center",
      }}
      {...props}
    />
  );
};

export default Component;
