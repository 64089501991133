import React, { useContext, useEffect, useMemo, useState } from "react";
import { Col, Row } from "antd";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { RELATIONSHIP } from "utils/constants";
import Item from "./Item";
import Header from "../Header";
import { fullWidth } from "../../Quotes/style";
import PegaHeader from "shared/components/Layout/Public/Header";
import { filter, get } from "lodash";
import Modal from "./Modal";
import { StoreContext } from "../../../shared/store";

const Summary = ({ questionnaires, contracts, plan, user }) => {
  const { t } = useTranslation(NAME_SPACES.PERSONAL_SPACE);
  const { invitationShown, setInvitationShown } = useContext(StoreContext);

  const MEMBERS = t("BENEFICIARIES", {
    returnObjects: true,
  });
  const hasBeneficiaries = !isEmpty(user.relatedPersons);
  const { relatedPersons } = user;
  const children = relatedPersons.filter(
    (e) => e.relationship === RELATIONSHIP.CHILD
  );
  const conjoint = get(
    relatedPersons.filter(
      (e) => e.relationship === RELATIONSHIP.WIFE || RELATIONSHIP.HUSBAND
    ),
    "[0]"
  );

  const UserColumn = useMemo(
    () => () =>
      (
        <Item
          title={MEMBERS.YOU}
          member={user}
          questionnaire={filter(
            get(questionnaires, "questionnaires.data"),
            (e) => {
              return get(e, "relatedPerson.id", null) === null;
            }
          )}
          contract={filter(get(contracts, "contractQuestions.data"), (e) => {
            return get(e, "relatedPerson.id", null) === null;
          })}
        />
      ),
    [questionnaires, contracts]
  );
  const ConjointColumn = useMemo(
    () => () =>
      hasBeneficiaries && (
        <Item
          title={MEMBERS.CONJOINT}
          member={conjoint}
          questionnaire={filter(
            get(questionnaires, "questionnaires.data"),
            (e) => get(e, "relatedPerson.id", null) === conjoint.id
          )}
          contract={filter(
            get(contracts, "contractQuestions.data"),
            (e) => get(e, "relatedPerson.id", null) === conjoint.id
          )}
        />
      ),
    [questionnaires, contracts]
  );
  const ChildrenColumn = useMemo(
    () => () =>
      children.map((member, index) => (
        <Item
          key={member.id}
          title={`${MEMBERS.CHILD} ${index + 1}`}
          member={member}
          questionnaire={filter(
            get(questionnaires, "questionnaires.data"),
            (e) => get(e, "relatedPerson.id", null) === member.id
          )}
          contract={filter(
            get(contracts, "contractQuestions.data"),
            (e) => get(e, "relatedPerson.id", null) === member.id
          )}
        />
      )),
    [questionnaires, contracts]
  );

  return (
    <div className={"on-boarding-container"}>
      <Row style={fullWidth}>
        <Col xl={{ span: 18, offset: 3 }} md={{ span: 20, offset: 2 }}>
          <PegaHeader />
          <Header
            name={user.firstname}
            children={
              relatedPersons.filter(
                (e) => e.relationship === RELATIONSHIP.CHILD
              ).length
            }
            spouse={
              relatedPersons.filter(
                (e) =>
                  e.relationship === RELATIONSHIP.WIFE || RELATIONSHIP.HUSBAND
              ).length
            }
            plan={plan.name}
            icon={plan.icon}
            username={user.username}
            passcode={"************"}
          />
          <br />
          <UserColumn />
          <ConjointColumn />
          <ChildrenColumn />
        </Col>
      </Row>
      {!invitationShown && (
        <Modal
          link={"https://b2c-invitaion/acces/Z038"}
          onCloseClick={() => {
            setInvitationShown(true);
          }}
        />
      )}
    </div>
  );
};
export default Summary;
