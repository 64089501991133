export const container = {
  display: "flex",
  width: "100%",
  height: "90vh",
  alignItems: "center",
  justifyContent: "center"
};

export const icon = {
  fontSize: 24,
  color: "#fe5d80"
};
