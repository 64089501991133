import React from "react";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import PropTypes from "prop-types";
import { MOBILE_MAX_WIDTH } from "utils/constants";

const Space = ({ ...props }) => {
  const { width } = useWindowDimensions();
  const sizes = ({ h, w, sh, sw }) => {
    const size = { h, w };
    if (width < MOBILE_MAX_WIDTH && (sw || sh)) {
      size.w = sw;
      size.h = sh;
    }
    return size;
  };
  return <div style={{ height: sizes(props).h, width: sizes(props).w }} />;
};

Space.propTypes = {
  h: PropTypes.number,
  w: PropTypes.number,
  sh: PropTypes.number,
  sw: PropTypes.number
};

Space.defaultProps = {};
export default Space;
