import React, { useState } from "react";

import PropTypes from "prop-types";
import "./styles.scss";

const VerticalMetric = ({ elements, power, icon }) => {
  return (
    <div className="vertical--metric">
      {Array(elements)
        .fill(0)
        .map((x, index) => {
          const powerVal = elements - power <= index;
          console.log(powerVal);
          return (
            <div
              className={`vertical--metric--item ${powerVal && "opacity"}`}
              key={index}
            ></div>
          );
        })}
      <div className="vertical--metric--icon">
        <img src={icon} alt="" />
      </div>
    </div>
  );
};

VerticalMetric.propTypes = {
  elements: PropTypes.number,
  power: PropTypes.number,
  icon: PropTypes.string,
};

VerticalMetric.defaultProps = {
  elements: 8,
  power: 3,
  icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Eye_open_font_awesome.svg/1200px-Eye_open_font_awesome.svg.png"
};

export default VerticalMetric;
