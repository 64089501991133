import React from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { StepStore } from "shared/components/StepScreens";
import { NAME_SPACES } from "shared/locales/constants";
import { fullWidth } from "../style";
import PegaHeader from "shared/components/Layout/Public/Header";
import { BINDING_DATE, SOCIAL_REGIMEN } from "../constants";
import HeadingBack from "shared/components/HeadingBack";
import Container from "shared/components/Container";

const InsuranceStatus = ({ step: { changeTab }, setUser }) => {
  const { t } = useTranslation(NAME_SPACES.ON_BOARDING);
  const STRINGS = t("INSURANCE_STATUS", {
    returnObjects: true,
  });
  return (
    <>
      <PegaHeader />
      <div className={"simulator--wrapper bg--linear-linear"}>
        <Container>
          <Row style={fullWidth} justify="center" align="center">
            <Col xl={16} md={16} sm={24} xs={24}>
              <div className="simulator--wrapper--head">
                <HeadingBack
                  onClick={() => changeTab(SOCIAL_REGIMEN)}
                  title={STRINGS.YOUR_INSURANCE_STATUS}
                />
              </div>

              <div className="simulator--wrapper--list">
                {Object.entries(STRINGS.TYPES).map(([key, value]) => (
                  <div
                    key={key}
                    onClick={() => {
                      setUser((user) => {
                        return { ...user, insuranceStatus: value };
                      });
                      changeTab(BINDING_DATE);
                    }}
                    className="simulator--wrapper--list--item"
                  >
                    {value}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

InsuranceStatus.propTypes = {
  step: PropTypes.shape({
    changeTab: PropTypes.func,
  }),
  setUser: PropTypes.func,
};

export default StepStore(InsuranceStatus);
