import React from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { container } from "./styles";

const Loading = () => (
  <div style={container}>
    <LoadingOutlined />
  </div>
);

export default Loading;
