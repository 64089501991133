import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";

const Item = ({ title, icon, onClick, disabled }) => (
  <div onClick={onClick} className={`item ${disabled && "disabled"}`}>
    {icon}
    <p> {title} </p>
  </div>
);

Item.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Item.defaultProps = {
  onClick: () => {},
};
export default Item;
