import gql from "graphql-tag";

export const UPDATE_USER = gql`
  mutation updateUser($data: InputUserUpdated) {
    updateUser(data: $data) @privateAuthBff
  }
`;

export const SIGN_UP = gql`
  mutation signUp($data: UserWhereInput) {
    signUp(data: $data) @privateAuthBff
  }
`;

export const SIGN_IN = gql`
  mutation signIn($data: InputUserAuth) {
    signIn(data: $data) @privateAuthBff {
      accessToken
      refreshToken
    }
  }
`;

export const CREATE_USER = gql`
mutation addUser($data: UserWhereInput) {
  addUser(data: $data)
}
`;

export const CREATE_PATIENT = gql`
  mutation addPatient($data: PatientDataInput) {
    addPatient(data: $data)
  }
`;
