import React, { useContext, useState } from "react";

import Button from "@taillislabs/button";
import cuid from "cuid";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import {
  CREATE_QUESTIONNAIRE,
  UPDATE_QUESTIONNAIRE,
} from "shared/graphql/mutations";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import { Row, Col } from "antd";
import Question from "./Question";
import questionnaireItems from "./questionaire";
import { fullWidth } from "../../Quotes/style";
import "../style.scss";
import PegaHeader from "shared/components/Layout/Public/Header";
import { useHistory, useLocation } from "react-router-dom";
import { get } from "lodash";
import qs from "qs";

const Questionnaire = () => {
  const { user } = useContext(StoreContext);
  const { t } = useTranslation(NAME_SPACES.PERSONAL_SPACE);
  const MEMBERS = t("BENEFICIARIES", {
    returnObjects: true,
  });
  const history = useHistory();
  const FORM = t("FORM", {
    returnObjects: true,
  });
  const location = useLocation();
  const { member } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  console.log(member);
  const { responses } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    depth: 10,
  });
  const [questionnaire, setQuestionnaire] = useState(
    get(responses, "[0].questionsAnswers", null) || questionnaireItems
  );
  const [createQuestionnaire] = useMutation(CREATE_QUESTIONNAIRE, {
    onCompleted: () => history.goBack(),
  });
  const [updateQuestionnaire] = useMutation(UPDATE_QUESTIONNAIRE, {
    onCompleted: () => history.goBack(),
  });
  const setQuestion = (order, question) => {
    const items = questionnaire;
    items[order] = question;
    setQuestionnaire([...items]);
  };

  return (
    <div className={"page"}>
      <Row style={fullWidth}>
        <Col xl={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
          <PegaHeader />
          <p className={"question-title"}>
            {member.relationship ? MEMBERS[member.relationship] : "Vous"}
          </p>
          <div>
            {questionnaire.map((e, index) => (
              <Question
                data={e}
                order={index}
                key={index}
                setQuestion={setQuestion}
              />
            ))}
            <Row>
              <Col xl={{ span: 4, offset: 20 }}>
                <Button
                  onClick={() => {
                    const responseId = get(responses, "[0].id", null);
                    const data = {
                      data: {
                        id: responseId || cuid(),
                        user: { id: user.id },
                        questionsAnswers: questionnaire,
                        relatedPerson: {
                          id: user.id === member.id ? null : member.id,
                        },
                      },
                    };
                    responseId
                      ? updateQuestionnaire({
                          variables: { where: { id: responseId }, ...data },
                        })
                      : createQuestionnaire({
                          variables: { ...data },
                        });
                  }}
                  bg={"#FE5D80"}
                  title={FORM.NEXT}
                  textStyle={{ color: "white" }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Questionnaire;
