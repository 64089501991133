import React from "react";

import Item from "./Item";

const Component = ({ items, onClick }) => {
  return items.map((data, index) => {
    return <Item data={data} key={index} onClick={()=>onClick(data.title)} />;
  });
};

Component.propTypes = {};

Component.defaultProps = {};

export default Component;
