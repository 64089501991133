import { jsPDF } from "jspdf";
import logo from "shared/assets/images/logo.png";
import { get } from "lodash";

export const generatePDF = ({ data, user, member, relationShips }) => {
  let doc = new jsPDF("p", "mm", "a4");
  doc.addImage(logo, "PNG", 16, 16);
  doc.setFontSize(12);
  doc.text(`${get(user, "lastname")} ${get(user, "firstname")}`, 16, 45);
  doc.text(`${get(user, "email")}`, 16, 53);
  doc.text(`${get(user, "phone")}`, 16, 61);
  doc.text(
    `Document pour ${relationShips[member.relationship] || "vous même"}`,
    16,
    80
  );

  let questionnaireText = "";
  get(data, "questionsAnswers", []).map((e, i) => {
    questionnaireText = `${questionnaireText}\n${e.question}: ${
      e.answer
    }\n${get(e, "subQuestions", []).map(
      (sub, i) => `\t${sub.question}: ${sub.answer}\n`
    )}`;
  });
  let lMargin = 15;
  let rMargin = 15;
  let pdfInMM = 210;
  let lines = doc.splitTextToSize(
    questionnaireText,
    pdfInMM - lMargin - rMargin
  );
  doc.text(lMargin, 90, lines);
  doc.save(`${get(user, "lastname")}_${get(user, "firstname")}.pdf`);
};
