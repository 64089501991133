import React from "react";
import propTypes from "prop-types";
import "./styles.scss";
import { FiArrowLeft } from "react-icons/fi";

const Container = ({ title, onClick }) => (
  <div className={"heading-back--wrapper"}>
    <div className="icon" onClick={onClick}>
      <FiArrowLeft size={24} />
    </div>
    <h3>{title}</h3>
  </div>
);

Container.defaultProps = {};

Container.prototype = {};

export default Container;
