import React from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { StepStore } from "shared/components/StepScreens";
import { NAME_SPACES } from "shared/locales/constants";
import { fullWidth } from "../style";
import PegaHeader from "shared/components/Layout/Public/Header";
import moment from "moment";
import cuid from "cuid";
import { INSURANCE_STATUS, PRODUCTS } from "../constants";
import HeadingBack from "shared/components/HeadingBack";
import Container from "shared/components/Container";
import DatePicker from "@taillislabs/datepicker";
import { AiOutlineCalendar } from "react-icons/ai";

const DATES = {
  TODAY: () => moment(),
  TOMORROW: () => moment().add(1, "day"),
  INAWEEK: () => moment().add(1, "week"),
  INAMONTH: () => moment().add(1, "month"),
};

const BindingDate = ({ step: { changeTab }, setUser }) => {
  const { t } = useTranslation(NAME_SPACES.ON_BOARDING);
  const STRINGS = t("BINDING_DATE", {
    returnObjects: true,
  });
  return (

    <>
    <PegaHeader />
    <div className={"simulator--wrapper bg--linear-linear"}>
      <Container>
        <Row style={fullWidth} justify="center" align="center">
          <Col xl={16} md={16} sm={24} xs={24}>
            <div className="simulator--wrapper--head">
              <HeadingBack
                onClick={() => changeTab(INSURANCE_STATUS)}
                title={STRINGS.YOUR_BINDING_DATE}
              />
              <p>Pour éviter une facturation au prorata, choisissez le premier d'un mois.</p>
            </div>

            <DatePicker
                size={"large"}
                suffixIcon={<AiOutlineCalendar size={24} />}
                onChange={(data) => {
                  setUser((user) => {
                    return { ...user, bindingDate: data };
                  });
                }}
              />

              <div className="simulator--wrapper--action">
                <div
                  class="button button-large"
                  onClick={() => changeTab(PRODUCTS)}
                >
                  <div id="circle"></div>
                  <a href="javascript:void(0)">Continuer</a>
                </div>
              </div>

          </Col>
        </Row>
      </Container>
    </div>
 
  </>

  );
};

BindingDate.propTypes = {
  step: PropTypes.shape({
    changeTab: PropTypes.func,
  }),
  setUser: PropTypes.func,
};

export default StepStore(BindingDate);
