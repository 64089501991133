import React, { useContext } from "react";

import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import Item from "./Item";
import Progress from "shared/components/Progress";
import { fullWidth } from "../../../Quotes/style";
import PropTypes from "prop-types";
import { PATHS } from "utils/constants";
import { useHistory } from "react-router";
import qs from "qs";
import { generatePDF } from "utils/helpers/pdf";
import { StoreContext } from "shared/store";
import download from "shared/assets/images/download.svg";
import upload from "shared/assets/images/upload.svg";
import questionnaireImg from "shared/assets/images/questionnaire.svg";
import contractImg from "shared/assets/images/contract.svg";

const Column = ({ title, member, questionnaire, contract }) => {
  const { t } = useTranslation(NAME_SPACES.PERSONAL_SPACE);
  const ITEMS = t("FORM", {
    returnObjects: true,
  });
  const BENEFICIARIES = t("BENEFICIARIES", {
    returnObjects: true,
  });
  const { user } = useContext(StoreContext);
  const history = useHistory();
  return (
    <div className={"line-item"}>
      <Row style={fullWidth} justify={"center"}>
        <Col xl={4} xs={24}>
          <div className={"vertical-centered"}>
            <p className={"line-title"}>{title}</p>
          </div>
        </Col>
        <Col xl={16}>
          <Row style={fullWidth}>
            <Col xl={6} xs={12}>
              <Item
                title={ITEMS.CONTRACT}
                icon={<img src={contractImg} width={18} />}
                onClick={() => {
                  history.push(
                    `${PATHS.GUEST.PERSONAL_SPACE.CONTRACT}?${qs.stringify({
                      member,
                      responses: contract,
                    })}`
                  );
                }}
              />
            </Col>
            <Col xl={6} xs={12}>
              <Item
                title={ITEMS.QUESTIONNAIRE}
                icon={<img src={questionnaireImg} width={18} />}
                onClick={() => {
                  history.push(
                    `${
                      PATHS.GUEST.PERSONAL_SPACE.MEDICAL_QUESTIONNAIRE
                    }?${qs.stringify({
                      member,
                      responses: questionnaire,
                    })}`
                  );
                }}
              />
            </Col>
            <Col xl={6} xs={12}>
              <Item
                title={ITEMS.DOWNLOAD}
                disabled={questionnaire.length === 0}
                icon={<img src={download} width={18} />}
                onClick={() => {
                  generatePDF({
                    data: questionnaire[0],
                    user,
                    member,
                    relationShips: BENEFICIARIES,
                  });
                }}
              />
            </Col>
            <Col xl={6} xs={12}>
              <Item
                disabled
                title={ITEMS.UPLOAD}
                icon={<img src={upload} width={18} />}
                onClick={() => {}}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <div className={"vertical-centered"}>
            <Progress progress={75} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

Column.propTypes = {
  title: PropTypes.string,
};

Column.defaultProps = {
  onClick: () => {},
};

export default Column;
