export const SUMMARY = "SUMMARY";
export const PRODUCTS = "PRODUCTS";
export const FINALIZE = "FINALIZE";
export const FINAL_STEP = "FINAL_STEP";
export const GENDER = "GENDER";
export const BIRTHDATE = "BIRTHDATE";
export const PROFESSION = "PROFESSION";
export const SOCIAL_REGIMEN = "SOCIAL_REGIMEN";
export const INSURANCE_STATUS = "INSURANCE_STATUS";
export const BINDING_DATE = "BINDING_DATE";
export const OFFERS = "OFFERS";
export const SUCCESS = "SUCCESS";
export const STEPS_LIST = [
  SUMMARY,
  GENDER,
  BIRTHDATE,
  PROFESSION,
  SOCIAL_REGIMEN,
  INSURANCE_STATUS,
  BINDING_DATE,
  PRODUCTS,
  FINALIZE,
  OFFERS,
  SUCCESS,
  // FINAL_STEP
];
