import React from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import HeadingBack from "shared/components/HeadingBack";
import Container from "shared/components/Container";
import { StepStore } from "shared/components/StepScreens";
import { NAME_SPACES } from "shared/locales/constants";
import { fullWidth } from "../style";
import PegaHeader from "shared/components/Layout/Public/Header";
import DatePicker from "@taillislabs/datepicker";
import { GENDER, PROFESSION } from "../constants";
import { AiOutlineCalendar } from "react-icons/ai";

const Birthdate = ({ step: { changeTab }, setUser }) => {
  const { t } = useTranslation(NAME_SPACES.ON_BOARDING);
  const STRINGS = t("BIRTHDATE", {
    returnObjects: true,
  });
  return (
    <>
      <PegaHeader />
      <div className={"simulator--wrapper bg--linear-linear"}>
        <Container>
          <Row style={fullWidth} justify="center" align="center">
            <Col xl={16} md={16} sm={24} xs={24}>
              <div className="simulator--wrapper--head">
                <HeadingBack 
                 onClick={() => changeTab(GENDER)}
                title={STRINGS.YOUR_BIRTHDATE} />
              </div>

              {/* <DatePicker
                placeholder={STRINGS.BIRTHDATE}
                size={"large"}
                suffixIcon={<AiOutlineCalendar size={24} />}
                onChange={(data) => {
                  setUser((user) => {
                    return { ...user, birthDate: data };
                  });
                }}
              /> */}

              <input type="date" className="custom--datepicker" placeholder={STRINGS.BIRTHDATE}  onChange={(data) => {
                  setUser((user) => {
                    return { ...user, birthDate: data };
                  });
                }} />

              <div className="simulator--wrapper--action">
                <div
                  class="button button-large"
                  onClick={() => changeTab(PROFESSION)}
                >
                  <div id="circle"></div>
                  <a href="javascript:void(0)">{STRINGS.OK}</a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Birthdate.propTypes = {
  step: PropTypes.shape({
    changeTab: PropTypes.func,
  }),
  setUser: PropTypes.func,
};

export default StepStore(Birthdate);
