import * as yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default (messages) => {
  return yup.object().shape({
    phone: yup
      .string()
      .required(messages.REQUIRED)
      .matches(phoneRegExp, messages.NUMBER)
      .min(10, messages.NUMBER)
      .max(10, messages.NUMBER),
    email: yup.string().email(messages.EMAIL).required(messages.REQUIRED),
  });
};
