/* eslint-disable import/prefer-default-export */
import gql from "graphql-tag";

export const GET_QUESTIONNAIRES = gql`
  query questionnaires($where: QuestionnaireWhereInput) {
    questionnaires(where: $where) {
      data {
        id
        createdDate
        relatedPerson {
          id
        }
        user {
          id
        }
        questionsAnswers
      }
    }
  }
`;

export const GET_QUESTIONNAIRE = gql`
  query questionnaire($where: QuestionnaireWhereInput) {
    questionnaire(where: $where) {
      id
      createdDate
      relatedPerson {
        id
      }
      user {
        id
      }
      questionsAnswers
    }
  }
`;
