/* eslint-disable consistent-return */
import React from "react";
import "./styles.css";

export default ({ placeholder, options, onChange }) => {
  return (
    <div className="component_select_wrapper">
      <select
        className="component_select"
        onChange={(e) => {
          onChange(e.target.selectedOptions["0"].value);
        }}
      >
        <option value="" className={"component_select_item"} disabled selected>
          {placeholder}
        </option>

        {options.map((option) => (
          <option className={"component_select_item"} value={option.value}>
            {option.code}
          </option>
        ))}
      </select>
    </div>
  );
};
