import React, { useEffect, useMemo } from "react";

import cuid from "cuid";
import moment from "moment";
import PropTypes from "prop-types";
import { useMutation } from "react-apollo";
import { useHistory } from "react-router";
import Loading from "shared/components/Loading";
import { StepStore } from "shared/components/StepScreens";
import {
  BUILD_CAPABILITY,
  CREATE_RELATED_PERSON,
} from "shared/graphql/mutations";
import {
  TIME_UNITS,
  PERMISSIONS,
  CAPABILITY_REASONS,
  META_TYPE,
  PATHS,
} from "utils/constants";

const FinalStep = ({ user }) => {
  const id = useMemo(() => cuid(), []);
  const [createCapability] = useMutation(BUILD_CAPABILITY);
  const [createRelatedPerson] = useMutation(CREATE_RELATED_PERSON);
  const history = useHistory();
  const relatedPersons = user.relatedPersons || [];
  useEffect(() => {

    console.log('yyy',user);
    createCapability({
      variables: {
        data: {
          id,
          permission: PERMISSIONS.READ,
          reason: CAPABILITY_REASONS.SIMULATION,
          user: { id: user.id },
          ressource: {
            id: user.id,
            type: META_TYPE.PROSPECT,
          },
          link: {
            id: cuid(),
            expiresAt: moment().add(2, TIME_UNITS.DAYS),
          },
        },
      },
    });
    relatedPersons.forEach((element) => {
      createRelatedPerson({ variables: { data: element } });
    });
    history.push(PATHS.GUEST.PERSONAL_SPACE.SUMMARY);
  }, []);
  return <Loading />;
};

FinalStep.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    relatedPersons: PropTypes.array,
  }),
};

export default StepStore(FinalStep);
