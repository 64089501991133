import React from "react";
import "./style.scss";
import { HELP_URL } from "utils/constants";
import icon from "shared/assets/images/help.svg";
// const logo = require("shared/assets/images/logo.png");
import Logo from "shared/components/Logo";
import Container from "shared/components/Container";
import { IoIosHelpBuoy } from "react-icons/io";

const Header = () => (
  <div className={"public--header"}>
    <Container>
      <div className="public--header--row">
        <Logo label={""} />
        <div className={"header-right"}>
          <div class="button button-link">
            <div id="circle"></div>
            <a href={HELP_URL} target={"blank"}>
              <IoIosHelpBuoy size={28} />
              <span>Besoin d'aide?</span>
            </a>
          </div>
        </div>
      </div>
    </Container>
  </div>
);

export default Header;
