import React from "react";
import Select from "shared/components/Select";
import "../style.scss";
const { Option } = Select;

const Component = ({
  str,
  options,
  width,
  value,
  setValue,
  onChange,
  ...props
}) => {
  return (
    <Select
      placeholder={str}
      style={{ width }}
      options={options.map(({ label, value }) => ({ value, code: label }))}
      onChange={(e) => {
        onChange(e);
      }}
      {...props}
    />
  );
};

export default Component;
