import React from "react";
import { renderRoutes } from "react-router-config";
import { Switch, withRouter } from "react-router-dom";
import screens from "screens";
import ScrollToTop from "./ScrollToTop";

const App = () => {
  return (
    <div>
      <ScrollToTop />
      <Switch>{renderRoutes(screens({}))}</Switch>
    </div>
  );
};

export default withRouter(App);
