/* eslint-disable import/prefer-default-export */
import gql from "graphql-tag";

export const LOGIN = gql`
  mutation LoginMutation($data: InputUserAuth) {
    signin(data: $data) @authBff {
      accessToken
      refreshToken
    }
  }
`;

export const GENERATE_PIN_CODE_FOR_SIGNIN = gql`
  mutation generatePhonePinForSignIn($data: InputPhoneVerification) {
    generatePhonePinForSignIn(data: $data) @authBff
  }
`;

export const SIGNIN_WITH_PHONE = gql`
  mutation signInWithPinAndPhone($data: InputUserAuthWithPhone) {
    signInWithPhone(data: $data) @authBff {
      accessToken
      refreshToken
    }
  }
`;

export const SING_IN_WITH_LINK = gql`
  mutation signInWithLink($data: LinkWhereInput) {
    signInWithLink(data: $data) @authBff {
      accessToken
    }
  }
`;
export const VALIDATE_MAIL = gql`
  mutation confirmMail($data: InputConfirmMail) {
    confirmMail(data: $data) @authBff
  }
`;
