import React from "react";
import "../styles.scss";
import propTypes from "prop-types";
import { Popover, Button } from "antd";

const Head = ({ title, icon, howChoose,titlePopover }) => {
  return (
    <div className="product--item--head">
      <div className="product--item--head--icon">
        <img src={icon} alt={title} />
      </div>
      <div className="product--item--head--title">
        <h4>{title}</h4>
        <Popover
          placement="bottom"
          content={howChoose}
          className="product--item--popover"
          trigger="click"
        >
          Comment le choisir ?
        </Popover>
      </div>
    </div>
  );
};

Head.prototype = {
  icon: propTypes.string,
  title: propTypes.string,
  howChoose: propTypes.node,
  titlePopover: propTypes.string
};

export default Head;
