import React, { useEffect, useState } from "react";

import { STORAGE_KEYS } from "utils/constants";

export const Context = React.createContext("store");

export const Consumer = Context.Consumer;

export const Provider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(STORAGE_KEYS.USER) || "{}")
  );
  const [invitationShown, setInvitationShown] = useState(
    JSON.parse(
      localStorage.getItem(STORAGE_KEYS.INVITATION) || JSON.stringify(false)
    )
  );
  const [token, setToken] = useState({
    accessToken: localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN),
    refreshToken: "",
  });
  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, token.accessToken);
  }, [token]);

  useEffect(() => {
    localStorage.setItem(
      STORAGE_KEYS.INVITATION,
      JSON.stringify(invitationShown)
    );
  }, [invitationShown]);

  const clearStore = () => {
    setUser({});
    setToken({});
    setInvitationShown({});
  };

  return (
    <Context.Provider
      value={{
        user,
        ...token,
        setUser,
        clearStore,
        setToken,
        setInvitationShown,
        invitationShown,
      }}
    >
      {children}
    </Context.Provider>
  );
};
