import React from "react";
import { Col, Radio, Row } from "antd";
import { fullWidth } from "../../Quotes/style";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import RadioButton from "shared/components/Radio";
import { Formik } from "formik";
import DatePicker from "@taillislabs/datepicker";
import { bindInputProps } from "utils/helpers/input";
import Input from "@taillislabs/input";
import Select from "shared/components/DropDown";
import Button from "@taillislabs/button";
import { CITIES, PARENTAL_LINK } from "utils/constants";
import { STATE } from "utils/constants";
import PegaHeader from "shared/components/Layout/Public/Header";
import male from "shared/assets/images/avatars1.svg";
import female from "shared/assets/images/avatars2.svg";

const Component = ({ initialValues, validation, onSubmit }) => {
  const { t } = useTranslation(NAME_SPACES.CONTRACT);
  const FORM = t("FORM", {
    returnObjects: true,
  });
  return (
    <div className={"page"}>
      <Row style={fullWidth}>
        <Col xl={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
          <PegaHeader />
        </Col>
      </Row>
      <Row style={fullWidth}>
        <Col
          xl={{ span: 18, offset: 3 }}
          md={{ span: 20, offset: 2 }}
          className={"sm-pad"}
        >
          <p className={"contract-title"}>{FORM.PROFILE}</p>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validation}
          >
            {({ handleSubmit, ...formProps }) => (
              <Col xs={24}>
                <p className={"date-placeholder mt-50"}>{FORM.CIVILITY}</p>
                <Radio.Group
                  {...bindInputProps({ name: "gender", ...formProps })}
                >
                  <div className={"drawer-radio"}>
                    <RadioButton
                      value={"m"}
                      label={FORM.MALE}
                      checked={formProps.values.gender === "m"}
                      variant={"large"}
                      icon={male}
                    />
                    <RadioButton
                      value={"f"}
                      label={FORM.FEMALE}
                      checked={formProps.values.gender === "f"}
                      variant={"large"}
                      icon={female}
                    />
                  </div>
                </Radio.Group>
                <div className={"mt-24"} />
                <Row gutter={[16, 16]}>
                  <Col xl={12} xs={24}>
                    <DatePicker
                      placeholder={FORM.BIRTHDATE}
                      {...bindInputProps({ name: "birthdate", ...formProps })}
                    />
                  </Col>
                  <Col xl={12} xs={24}>
                    <Input
                      placeholder={FORM.NAME}
                      {...bindInputProps({ name: "name", ...formProps })}
                    />
                  </Col>
                </Row>
                <div className={"mt-24"} />
                <Row gutter={[16, 16]}>
                  <Col xl={12} xs={24}>
                    <Select
                      items={STATE.map((el) => ({
                        label: el,
                        value: el,
                      }))}
                      placeholder={FORM.STATE}
                      {...bindInputProps({ name: "state", ...formProps })}
                    />
                  </Col>
                  <Col xl={12} xs={24}>
                    <Select
                      items={PARENTAL_LINK.map((el) => ({
                        label: FORM[el],
                        value: el,
                      }))}
                      placeholder={FORM.PARENTAL}
                      {...bindInputProps({ name: "parental", ...formProps })}
                    />
                  </Col>
                </Row>
                <div className={"mt-24"} />
                <Row gutter={[16, 16]}>
                  <Col xl={12} xs={24}>
                    <Input
                      placeholder={FORM.ADDRESS}
                      {...bindInputProps({ name: "address", ...formProps })}
                    />
                  </Col>
                  <Col xl={12} xs={24}>
                    <Select
                      items={CITIES.map((el) => ({
                        label: el,
                        value: el,
                      }))}
                      placeholder={FORM.CITY}
                      {...bindInputProps({ name: "city", ...formProps })}
                    />
                  </Col>
                </Row>
                <div className={"mt-24"} />
                <Row gutter={[16, 16]}>
                  <Col xl={12} xs={24}>
                    <Input
                      placeholder={FORM.EMAIL}
                      {...bindInputProps({ name: "email", ...formProps })}
                    />
                  </Col>
                  <Col xl={12} xs={24}>
                    <Input
                      placeholder={FORM.PHONE}
                      {...bindInputProps({ name: "phone", ...formProps })}
                    />
                  </Col>
                </Row>
                <div className={"mt-24"} />
                <Row gutter={[16]}>
                  <Col xl={12} xs={24}>
                    <Input
                      type={"number"}
                      placeholder={FORM.WEIGHT}
                      {...bindInputProps({ name: "weight", ...formProps })}
                    />
                  </Col>
                  <Col xl={12} xs={24}>
                    <Input
                      type={"number"}
                      placeholder={FORM.HEIGHT}
                      {...bindInputProps({ name: "height", ...formProps })}
                    />
                  </Col>
                </Row>
                <div className={"mt-24"} />
                <Row gutter={12}>
                  <Col xl={{ span: 3 }} xs={12}>
                    <Button
                      onClick={() => {}}
                      bg={"#FE5D80"}
                      title={FORM.PREVIOUS}
                      textStyle={{ color: "white" }}
                    />
                  </Col>
                  <Col xl={{ span: 3, offset: 18 }} xs={12}>
                    <Button
                      onClick={handleSubmit}
                      bg={"#FE5D80"}
                      title={FORM.CONFIRM}
                      textStyle={{ color: "white" }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

Component.propTypes = {};

Component.defaultProps = {};
export default Component;
