import { PATHS } from "../../utils/constants";

import Quotes from "./View";

const routes = [
  {
    path: PATHS.PUBLIC.QUOTES,
    exact: true,
    component: Quotes,
  },
];

export default routes;
