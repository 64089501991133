import React from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import HeadingBack from "shared/components/HeadingBack";
import Product from "shared/components/Product";
import { Formik } from "formik";
import Container from "shared/components/Container";
import { StepStore } from "shared/components/StepScreens";
import { NAME_SPACES } from "shared/locales/constants";
import { fullWidth } from "../style";
import PegaHeader from "shared/components/Layout/Public/Header";
import { BINDING_DATE, FINALIZE, FINAL_STEP } from "../constants";
import "./styles.scss";

const PRODUCT_DATA = [
  {
    key: "optique",
    title: "Optique",
    icon: require("shared/assets/images/products/product--icon--optique.svg"),
    description: "Exemples de remboursement pour une paire de lunettes",
  },
  {
    key: "dentaire",
    title: "Dentaire",
    icon: require("shared/assets/images/products/product--icon--dentaire.svg"),
    description: "Exemples de remboursement pour une prothèse",
  },
  {
    key: "hospitalisation",
    title: "Hospitalisation",
    icon: require("shared/assets/images/products/product--icon--hospitalisation.svg"),
    description: "Exemples de remboursement pour des frais de séjour",
  },
  {
    key: "soins",
    title: "Soins médicaux",
    icon: require("shared/assets/images/products/product--icon--soins.svg"),
    description: "Exemples de remboursement pour une radio",
  },
  {
    key: "aides",
    title: "Aides auditives",
    icon: require("shared/assets/images/products/product--icon--aide.svg"),
    description: "Exemples de remboursement pour un appareil auditif",
  },
];

const Products = ({ step: { changeTab }, user, setUser }) => {
  const { t } = useTranslation(NAME_SPACES.ON_BOARDING);
  const STRINGS = t("PRODUCT", {
    returnObjects: true,
  });
  return (
    <>
      <PegaHeader />
      <div className={"simulator--wrapper bg--linear-linear"}>
        <Container>
          <Row style={fullWidth} justify="center" align="center">
            <Col xl={16} md={16} sm={24} xs={24}>
              <div className="simulator--wrapper--head">
                <HeadingBack
                  onClick={() => changeTab(BINDING_DATE)}
                  title={"Sélectionnez votre niveau de remboursement"}
                />
              </div>

              {PRODUCT_DATA.map((element) => (
                <Product
                  icon={element.icon}
                  title={element.title}
                  description={element.description}
                  onPress={(value) =>
                    setUser({
                      ...user,
                      products: { ...user.products, [element.key]: value },
                    })
                  }
                />
              ))}

              <div className="simulator--wrapper--action">
                <div
                  class="button button-large"
                  onClick={() => changeTab(FINALIZE)}
                >
                  <div id="circle"></div>
                  <a href="javascript:void(0)">{STRINGS.OK}</a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Products.propTypes = {
  step: PropTypes.shape({
    changeTab: PropTypes.func,
  }),
  setUser: PropTypes.func,
};

export default StepStore(Products);
