/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "./App";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import "./shared/locales/i18n";
import { ApolloProvider as ApolloProviderHooks } from "@apollo/react-hooks";
import { ApolloProvider } from "react-apollo";
import client from "./shared/graphql";
import { StoreProvider } from "shared/store";

const Component = () => (
  <ApolloProvider client={client}>
    <ApolloProviderHooks client={client}>
      <StoreProvider>
        <HashRouter>
          <App />
        </HashRouter>
      </StoreProvider>
    </ApolloProviderHooks>
  </ApolloProvider>
);

const render = () => {
  ReactDOM.render(<Component />, document.getElementById("root"));
};

render();

serviceWorker.unregister();
