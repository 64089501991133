import React, { useMemo, useState } from "react";
import { Col, Row } from "antd";
import cuid from "cuid";
import { Formik } from "formik";
import { times } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ParagraphInput from "shared/components/ParagraphInput";
import Space from "shared/components/Space";
import { StepStore } from "shared/components/StepScreens";
import Container from "shared/components/Container";
import { NAME_SPACES } from "shared/locales/constants";
import { RELATIONSHIP } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import { GENDER } from "../constants";
import { fullWidth } from "../style";
import { AGE, CHILDREN, CITIES, SITUATION } from "./options";
import validation from "./validation";
import { ORGANIZATION_ID } from "utils/constants";
import Rocket from "shared/assets/images/svg/pega--rocket--icon.svg";
import illusRight from "shared/assets/images/face1.svg";
import shapeBottom from "shared/assets/images/svg/page--shape--bottom.svg";
import family from "shared/assets/images/family.svg";
import PegaHeader from "shared/components/Layout/Public/Header";
import database from "shared/firebase";

const Summary = ({ step: { changeTab }, setUser }) => {
  const { t } = useTranslation(NAME_SPACES.ON_BOARDING);
  const STRINGS = t("SUMMARY", {
    returnObjects: true,
  });

  const id = useMemo(() => cuid(), []);
  const password = useMemo(() => cuid.slug(), []);

  return (
    <Formik
      initialValues={{
        city: "",
        fullName: "",
        job: "",
        age: "",
        children: "",
        situation: "",
      }}
      onSubmit={(data) => {
        const relatedPersons =
          data.situation === "married"
            ? [
                {
                  id: cuid(),
                  relationship: RELATIONSHIP.WIFE,
                  user: { id },
                },
                ...times(parseInt(data.children || 0), () => ({
                  id: cuid(),
                  relationship: RELATIONSHIP.CHILD,
                  user: { id },
                })),
              ]
            : [];
        const { fullName } = data;
        setUser({
          id,
          city: data.city,
          age: data.age,
          username: fullName,
          firstname: fullName.split(" ")[0],
          lastname: fullName.split(" ")[1] || fullName,
          password,
          meta: {
            type: "prospect",
          },
          relatedPersons,
          organization: {
            id: ORGANIZATION_ID,
          },
        });
        changeTab(GENDER);
      }}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ handleSubmit, values, ...formProps }) => (
        <>
          <PegaHeader />
          <div
            className={"simulator--wrapper"}
            style={{ backgroundImage: `url(${shapeBottom})` }}
          >
            <Container>
              <Row style={fullWidth} justify="center" align="center">
                <Col xl={24} md={24}>
                  <div className="heading--summary p-top-40">
                    <img src={Rocket} alt="" />
                    <h3>Vos contrats en 2 minutes </h3>
                    <p>
                      En seulement quelques clics,
                      <br /> réunissons les informations restantes pour remplir
                      et générer vos contrats.
                    </p>
                  </div>
                </Col>
              </Row>

              <div className="simulator--wrapper--step-one">
                <div className={"mt-50"} />
                <p className={"boarding-content"}>
                  <ParagraphInput
                    text={STRINGS.MY_NAME}
                    className={"boarding-input"}
                    width={200}
                    {...bindInputProps({
                      name: "fullName",
                      values,
                      ...formProps,
                    })}
                  />
                </p>
                <p className={"boarding-content"}>
                  <ParagraphInput
                    text={STRINGS.RESIDENCY}
                    className={"boarding-input"}
                    options={CITIES}
                    width={100}
                    // mode={"input"}
                    {...bindInputProps({
                      name: "city",
                      values,
                      ...formProps,
                    })}
                  />
                </p>
                <p className={"boarding-content"}>
                  {/* <Row> */}
                  <ParagraphInput
                    text={STRINGS.HAVE}
                    className={"boarding-input"}
                    options={AGE}
                    mode={"select"}
                    width={100}
                    {...bindInputProps({ name: "age", values, ...formProps })}
                  />
                </p>
                <p className={"boarding-content"}>
                  <Space w={16} />
                  <ParagraphInput
                    text={STRINGS.RELATIONSHIP}
                    className={"boarding-input"}
                    options={SITUATION}
                    mode={"select"}
                    width={200}
                    {...bindInputProps({
                      name: "situation",
                      values,
                      ...formProps,
                    })}
                  />
                </p>
                <p>
                  <Space h={16} />
                  {values.situation === SITUATION[0].value && (
                    <ParagraphInput
                      text={STRINGS.CHILDREN}
                      className={"boarding-input"}
                      options={CHILDREN}
                      mode={"select"}
                      width={120}
                      {...bindInputProps({
                        name: "children",
                        values,
                        ...formProps,
                      })}
                    />
                  )}
                  {/* </Row> */}
                </p>
              </div>
              <div className="simulator--wrapper--action">
                <div class="button button-large" onClick={handleSubmit}>
                  <div id="circle"></div>
                  <a href="javascript:void(0)">{STRINGS.START}</a>
                </div>
              </div>
            </Container>
          </div>
        </>
      )}
    </Formik>
  );
};

Summary.propTypes = {
  step: PropTypes.shape({
    changeTab: PropTypes.func,
  }),
  setUser: PropTypes.func,
};

export default StepStore(Summary);
