import gql from "graphql-tag";

export const CREATE_QUESTIONNAIRE = gql`
  mutation addQuestionnaire($data: QuestionnaireWhereInput) {
    addQuestionnaire(data: $data)
  }
`;
export const UPDATE_QUESTIONNAIRE = gql`
  mutation updateQuestionnaire(
    $where: QuestionnaireWhereInput
    $data: QuestionnaireWhereInput
  ) {
    updateQuestionnaire(where: $where, data: $data)
  }
`;
