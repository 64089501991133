import React from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import HeadingBack from "shared/components/HeadingBack";
import Offer from "shared/components/Offers";
import Container from "shared/components/Container";
import { StepStore } from "shared/components/StepScreens";
import { NAME_SPACES } from "shared/locales/constants";
import { fullWidth } from "../style";
import PegaHeader from "shared/components/Layout/Public/Header";
import {
  BINDING_DATE,
  FINALIZE,
  FINAL_STEP,
  OFFERS,
  SUCCESS,
} from "../constants";
import { BsFillCircleFill } from "react-icons/bs";
import "./styles.scss";
import { CheckOutlined } from "@ant-design/icons";
import { FiCheck } from "react-icons/fi";

const OFFERSDATA = [
  {
    key: "basic",
    title: "MODULARIS Basic",
    desc: 'Prise en charge à 100% sur tous les postes de soin',
    price: '28.11',
    icon: require("shared/assets/images/offers/basic.svg"),
    metrics: [
      {
        power: 5,
        icon: require("shared/assets/images/products/product--icon--optique.svg"),
      },
      {
        power: 5,
        icon: require("shared/assets/images/products/product--icon--dentaire.svg"),
      },
      {
        power: 5,
        icon: require("shared/assets/images/products/product--icon--hospitalisation.svg"),
      },
      {
        power: 5,
        icon: require("shared/assets/images/products/product--icon--soins.svg"),
      },
      {
        power: 8,
        icon: require("shared/assets/images/products/product--icon--aide.svg"),
      },
    ],
    details: [
      {
        key: "100% santé",
        value: <FiCheck />,
      },
      {
        key: "Tiers payant",
        value: <FiCheck />,
      },
      {
        key: "Délai de carence",
        value: "No",
      },
      {
        key: "Frais de dossier",
        value: "No",
      },
    ],
  },
  {
    key: "opti",
    title: "MODULARIS Opti+ /Denti +",
    desc: 'Prise en charge des Lunettes avec un forfait jusqu\'à 350€',
    price: '51.65',
    icon: require("shared/assets/images/offers/optique.svg"),
    metrics: [
      {
        power: 7,
        icon: require("shared/assets/images/products/product--icon--optique.svg"),
      },
      {
        power: 7,
        icon: require("shared/assets/images/products/product--icon--dentaire.svg"),
      },
      {
        power: 6,
        icon: require("shared/assets/images/products/product--icon--hospitalisation.svg"),
      },
      {
        power: 6,
        icon: require("shared/assets/images/products/product--icon--soins.svg"),
      },
      {
        power: 8,
        icon: require("shared/assets/images/products/product--icon--aide.svg"),
      },
    ],
  },
  {
    key: "hospi",
    title: "MODULARIS Hospi +",
    desc: '300% de remboursement honoraires des médecins hospitaliers + un forfait de 90€/jour pour la chambre particulière',
    price: '45.70',
    icon: require("shared/assets/images/offers/hospi.svg"),
    metrics: [
      {
        power: 6,
        icon: require("shared/assets/images/products/product--icon--optique.svg"),
      },
      {
        power: 6,
        icon: require("shared/assets/images/products/product--icon--dentaire.svg"),
      },
      {
        power: 8,
        icon: require("shared/assets/images/products/product--icon--hospitalisation.svg"),
      },
      {
        power: 6,
        icon: require("shared/assets/images/products/product--icon--soins.svg"),
      },
      {
        power: 8,
        icon: require("shared/assets/images/products/product--icon--aide.svg"),
      },
    ],
  },
  {
    key: "max",
    title: "MODULARIS MAX",
    desc: 'TOP Niveau de couverture à tous les postes de soins',
    price: '60.51',
    icon: require("shared/assets/images/offers/max.svg"),
    metrics: [
      {
        power: 8,
        icon: require("shared/assets/images/products/product--icon--optique.svg"),
      },
      {
        power: 8,
        icon: require("shared/assets/images/products/product--icon--dentaire.svg"),
      },
      {
        power: 8,
        icon: require("shared/assets/images/products/product--icon--hospitalisation.svg"),
      },
      {
        power: 8,
        icon: require("shared/assets/images/products/product--icon--soins.svg"),
      },
      {
        power: 8,
        icon: require("shared/assets/images/products/product--icon--aide.svg"),
      },
    ],
  },
];

const Offers = ({ step: { changeTab }, setUser, user }) => {
  const { t } = useTranslation(NAME_SPACES.ON_BOARDING);
  const STRINGS = t("PRODUCT", {
    returnObjects: true,
  });
  return (
    <>
      <PegaHeader />
      <div className={"simulator--wrapper bg--linear-linear height-100"}>
        <Container>
          <Row style={fullWidth} justify="center" align="center">
            <Col xl={24} md={24} sm={24}>
              <div className="simulator--wrapper--head">
                <HeadingBack
                  onClick={() => changeTab(FINALIZE)}
                  title={"Sélectionnez votre niveau de remboursement"}
                />
                <div className="simulator--wrapper--head--desc">
                  <p>- Vos résultats, non exhaustifs triés par prix croissants</p>   
                  <p>- Sans frais supplémentaires</p>  
                  <p>- 10 assureurs sollicités, référencement non payant.</p>
                </div>
              </div>

              <Offer
                items={OFFERSDATA}
                mode={'simple'}
                onClick={(value) => {
                  setUser(() => {
                    return { ...user,  offers:{...user.offers, [OFFERSDATA.key]: value} };
                  });
                  changeTab(SUCCESS);  
                }}
               
              />
              <div className="p-bottom-40" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Offers.propTypes = {
  step: PropTypes.shape({
    changeTab: PropTypes.func,
  }),
  setUser: PropTypes.func,
};

export default StepStore(Offers);
