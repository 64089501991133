export default [
  {
    question:
      "Avez-vous souffrez-vous actuellement d'une infirmité ou d'une maladie quelconques ?",
    answer: null,
    subQuestions: [
      {
        question: "Type de maladie ou d'infirmité",
        type: "input",
        answer: null,
      },
      {
        question: "Depuis quelle date ?",
        type: "input",
        answer: null,
      },
      {
        question: "Traitement",
        type: "input",
        answer: null,
      },
    ],
  },
  {
    question: "Etes-vous actuellement en arrêt de travail meme partiel ?",
    answer: null,
    subQuestions: [
      {
        question: "Raison",
        type: "input",
        answer: null,
      },
    ],
  },
  {
    question: "Suivez-vous actuellement un traitement médical ?",
    answer: null,
    subQuestions: [],
  },
  {
    question:
      "Au cours des 10 dernières années, avez-vous présenté une maladie ou un accident ayant nécessité un ou plusieurs arrêts de travail de plus de 3 semaines consécutives?",
    answer: null,
    subQuestions: [],
  },
  {
    question:
      "Au cours des 10 dernières années, avez-vous présenté une maladie ou un accident ayant nécessité un traitement de plus de 3 semaines consécutives ?",
    answer: null,
    subQuestions: [],
  },
  {
    question:
      "Avez-vous séjourné en hôpital, clinique, maison de Santé, autres établissements de soins ou subi une intervention chirurgicale ?",
    answer: null,
    subQuestions: [],
  },

  {
    question:
      "Etes-vous atteint d’une infirmité ou d’une diminution de vos capacités physiques ?",
    answer: null,
    subQuestions: [],
  },
  {
    question:
      "Avez-vous été victime d’accident(s) ayant laissé des séquelles ?",
    answer: null,
    subQuestions: [],
  },
];
