/* eslint-disable import/prefer-default-export */
import gql from "graphql-tag";

export const GET_CONTRACT_QUESTIONS = gql`
  query contractQuestions($where: ContractQuestionWhereInput) {
    contractQuestions(where: $where) {
      data {
        id
        createdDate
        relatedPerson {
          id
        }
        user {
          id
        }
        answers
      }
    }
  }
`;

export const GET_CONTRACT_QUESTION = gql`
  query contractQuestion($where: ContractQuestionWhereInput) {
    contractQuestion(where: $where) {
      id
      createdDate
      relatedPerson {
        id
      }
      user {
        id
      }
      answers
    }
  }
`;
