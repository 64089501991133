export const bindInputProps = ({
  name,
  values,
  handleChange,
  setFieldTouched,
  touched,
  errors,
}) => {
  return {
    value: values[name] || "",
    onChange: handleChange(name),
    onBlur: () => setFieldTouched(name),
    touched: touched[name],
    errors: errors[name],
    name,
  };
};

export const bindArrayInputProps = ({
  parent,
  index,
  name,
  values,
  handleChange,
  setFieldTouched,
  touched,
  errors,
}) => {
  const childName = `${parent}.${index}.${name}`;
  return {
    value: values[parent][index][name] || "",
    onChange: handleChange(childName),
    onBlur: () => setFieldTouched(childName),
    touched: touched[childName],
    errors: errors[childName],
    name: childName,
  };
};
