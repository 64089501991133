/* eslint-disable import/prefer-default-export */
import boarding from "./boarding";
import errors from "./errors";
import personalSpace from "./personalSpace";
import contract from "./contract";

export const frTranslation = {
  boarding,
  errors,
  personalSpace,
  contract,
};
